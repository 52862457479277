const APP = () => import('@/App.vue');

const MAIN_ROUTES = {
  SIGN_IN: () => import('@/components/sign-in/SignIn.vue'),
  SUPPORT_SIGN_IN: () => import('@/components/sign-in/SupportSignIn.vue'),
  DASHBOARD: () => import('@/views/Dashboard.vue'),
  CATALOG: () => import('@/views/Catalog.vue'),
  BUSINESS_GLOSSARY: () => import('@/views/Business-Glossary.vue'),
  MONITORS: () => import('@/views/Monitors.vue'),
  INTEGRATIONS: () => import('@/views/Integrations.vue'),
  DATASOURCES: () => import('@/views/Datasources.vue'),
  SETTINGS: () => import('@/views/Settings.vue'),
  INCIDENTS: () => import('@/views/Incidents.vue'),
  DESIGN_SYSTEM: () => import('@/views/Design-System.vue'),
  DOMAINS: () => import('@/views/Domains.vue'),
  GRAPH_TEST: () => import('@/components/graph-test/Graph-Test.vue'),
  PREFERENCES: () => import('@/modules/preferences/pages/Preferences.vue'),
};

const SUB_ROUTES = {
  DASHBOARD: () => import('@/components/dashboard/Dashboard.vue'),

  CATALOG_SEARCH: () => import('@/components/catalog/Catalog.vue'),

  ASSET: () => import('@/components/asset/Asset.vue'),
  ASSET_OVERVIEW: () => import('@/components/asset/tabs/Asset-Tab-Overview.vue'),
  ASSET_DBT: () => import('@/components/asset/tabs/Asset-Tab-Dbt.vue'),
  ASSET_SCHEMA: () => import('@/components/asset/tabs/Asset-Tab-Schema.vue'),
  ASSET_MONITORS: () => import('@/modules/catalog/asset/tabs/AssetMonitors.vue'),
  ASSET_INCIDENTS: () => import('@/modules/catalog/asset/tabs/AssetIncidents.vue'),
  ASSET_LINEAGE: () => import('@/components/asset/tabs/Asset-Tab-Lineage.vue'),
  ASSET_DOCUMENTATION: () => import('@/components/asset/tabs/Asset-Tab-Documentation.vue'),

  BUSINESS_GLOSSARY: () => import('@/components/business-glossary/Business-Glossary.vue'),

  MONITORS_SEARCH: () => import('@/components/monitors/Monitors.vue'),

  MONITOR: () => import('@/modules/monitors/monitor/Monitor.vue'),
  MONITOR_OVERVIEW: () => import('@/modules/monitors/monitor/tabs/overview/MonitorOverview.vue'),

  RULE_OVERVIEW: () => import('@/components/rule/tabs/Rule-Tab-Overview.vue'),
  RULE_RUNS: () => import('@/components/rule/tabs/Rule-Tab-Runs.vue'),
  RULE_DETAILS: () => import('@/components/rule/tabs/Rule-Tab-Details.vue'),

  RULE_ADD: () => import('@/modules/monitors/monitor-wizard/Monitor-Wizard.vue'),
  RULE_EDIT: () => import('@/modules/monitors/monitor-wizard/Monitor-Wizard.vue'),

  INTEGRATION_CREDENTIALS: () => import('@/components/credentials/Credentials.vue'),

  DATASOURCES_SEARCH: () => import('@/modules/integrations/sources/Sources.vue'),

  DATASOURCE: () => import('@/modules/integrations/source/Source.vue'),
  DATASOURCE_OVERVIEW: () => import('@/modules/integrations/source/SourceOverview.vue'),
  DATASOURCE_RUNS: () => import('@/modules/integrations/source/SourceRuns.vue'),
  DATASOURCE_ADD: () => import('@/modules/integrations/source-wizard/SourceWizard.vue'),
  INTEGRATION_ADD: () => import('@/modules/integrations/source-wizard/IntegrationWizard.vue'),
  DATASOURCE_EDIT: () => import('@/modules/integrations/source-wizard/SourceWizard.vue'),
  INTEGRATION_EDIT: () => import('@/modules/integrations/source-wizard/IntegrationWizard.vue'),
  INTEGRATION_LIST: () => import('@/modules/integrations/sources/Integrations.vue'),

  SETTINGS_TAGS: () => import('@/components/tag/Tags.vue'),
  SETTINGS_USERS: () => import('@/components/users/Users.vue'),
  SETTINGS_TOKENS: () => import('@/components/access-tokens/AccessTokens.vue'),
  SETTINGS_SSO: () => import('@/modules/settings/sso/pages/Sso.vue'),
  SETTINGS_TOOLS: () => import('@/modules/collaboration-tools/pages/CollaborationTools.vue'),

  MONITORING_TABLE_METADATA: () => import('@/modules/monitors/metadata/TableMetadataMonitoring.vue'),

  DOMAINS: () => import('@/modules/domains/Domains.vue'),
  DOMAINS_EDIT: () => import('@/modules/domains/DomainsEdit.vue'),

  INCIDENTS: () => import('@/components/incidents/Incidents.vue'),

  INCIDENT: () => import('@/modules/incidents/incident/Incident.vue'),
  INCIDENT_OVERVIEW: () => import('@/modules/incidents/incident/tabs/overview/IncidentOverview.vue'),
  INCIDENT_LINEAGE: () => import('@/modules/incidents/incident/tabs/lineage/IncidentLineage.vue'),
};

export { APP, MAIN_ROUTES, SUB_ROUTES };
