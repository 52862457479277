/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type SystemPreferenceDto = {
    type?: SystemPreferenceDto.type;
};

export namespace SystemPreferenceDto {

    export enum type {
        USER_PERMISSION = 'USER_PERMISSION',
        STRING = 'STRING',
        SOURCE_FAILURE_NOTIFICATIONS = 'SOURCE_FAILURE_NOTIFICATIONS',
    }


}
