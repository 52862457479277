/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateIntegrationRequestDto } from '../models/CreateIntegrationRequestDto';
import type { IntegrationDto } from '../models/IntegrationDto';
import type { IntegrationListDto } from '../models/IntegrationListDto';
import type { QlikIntegrationParamsDto } from '../models/QlikIntegrationParamsDto';
import type { SnowflakeIntegrationParamsDto } from '../models/SnowflakeIntegrationParamsDto';
import type { UpdateIntegrationRequestDto } from '../models/UpdateIntegrationRequestDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class IntegrationService {

    /**
     * Get all integrations
     * @returns IntegrationListDto Successfully fetch integration
     * @throws ApiError
     */
    public static getAllIntegrations(): CancelablePromise<Array<IntegrationListDto>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/integrations',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Create a integration
     * @returns IntegrationDto Successfully create integration
     * @throws ApiError
     */
    public static createIntegration({
        requestBody,
    }: {
        requestBody: CreateIntegrationRequestDto,
    }): CancelablePromise<IntegrationDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/integrations',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * List all of integration param types
     * @returns any Successfully fetch integration params
     * @throws ApiError
     */
    public static getAllIntegrationParamTypes(): CancelablePromise<Array<(QlikIntegrationParamsDto | SnowflakeIntegrationParamsDto)>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/integrations/params',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Delete integration by given id
     * @returns void
     * @throws ApiError
     */
    public static deleteIntegrationById({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/ui/v1/integrations/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Get a single integration
     * @returns IntegrationDto Successfully fetch integration
     * @throws ApiError
     */
    public static getIntegrationById({
        id,
    }: {
        id: string,
    }): CancelablePromise<IntegrationDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/integrations/{id}',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update an integration
     * @returns IntegrationDto Successfully update integration
     * @throws ApiError
     */
    public static updateIntegration({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateIntegrationRequestDto,
    }): CancelablePromise<IntegrationDto> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ui/v1/integrations/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Manually trigger of integration metadata ingestion job
     * @returns void
     * @throws ApiError
     */
    public static integrationIngestionManualRun({
        id,
    }: {
        id: string,
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/ui/v1/integrations/{id}/_run',
            path: {
                'id': id,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Resource not found`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
