/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SystemSourceFailureNotificationPreferenceDto } from '../models/SystemSourceFailureNotificationPreferenceDto';
import type { SystemStringPreferenceDto } from '../models/SystemStringPreferenceDto';
import type { SystemUserPermissionPreferenceDto } from '../models/SystemUserPermissionPreferenceDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PreferencesService {

    /**
     * Fetch the value of a system preference
     * @returns any Successfully fetched system preference value
     * @throws ApiError
     */
    public static getSystemPreference({
        name,
    }: {
        name: 'METADATA_GENERATION_ENABLED' | 'SUPPORT_ACCESS_ENABLED' | 'SUPPORT_USER_PERMISSIONS' | 'SOURCE_FAILURE_NOTIFICATIONS_ENABLED' | 'SOURCE_FAILURE_NOTIFICATIONS_CONFIG',
    }): CancelablePromise<(SystemSourceFailureNotificationPreferenceDto | SystemStringPreferenceDto | SystemUserPermissionPreferenceDto)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/ui/v1/preferences/{name}',
            path: {
                'name': name,
            },
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

    /**
     * Update a system preference value
     * @returns void
     * @throws ApiError
     */
    public static updateSystemPreferenceByName({
        name,
        requestBody,
    }: {
        name: 'METADATA_GENERATION_ENABLED' | 'SUPPORT_ACCESS_ENABLED' | 'SUPPORT_USER_PERMISSIONS' | 'SOURCE_FAILURE_NOTIFICATIONS_ENABLED' | 'SOURCE_FAILURE_NOTIFICATIONS_CONFIG',
        requestBody: (SystemSourceFailureNotificationPreferenceDto | SystemStringPreferenceDto | SystemUserPermissionPreferenceDto),
    }): CancelablePromise<void> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/ui/v1/preferences/{name}',
            path: {
                'name': name,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                409: `Conflict`,
                500: `Internal server error`,
            },
        });
    }

}
