/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { DescriptionDto } from './DescriptionDto';
import type { DescriptionPredictionDto } from './DescriptionPredictionDto';
import type { LastRunStatusDto } from './LastRunStatusDto';
import type { RuleStatusDto } from './RuleStatusDto';
import type { TagDto } from './TagDto';

export type FullSchemaFieldDto = {
    description?: string;
    descriptionPredictions?: Array<DescriptionPredictionDto>;
    entityType: FullSchemaFieldDto.entityType;
    externalDescriptions?: Array<DescriptionDto>;
    id?: string;
    lastRunStatuses: Array<LastRunStatusDto>;
    monitored?: boolean;
    name: string;
    parentDatasetFieldId?: string;
    predictedTagsWaitingForFeedback?: Array<TagDto>;
    ruleStatuses: Array<RuleStatusDto>;
    subfields?: Array<FullSchemaFieldDto>;
    tags?: Array<TagDto>;
    terms?: Array<TagDto>;
    type: string;
    typeCategory?: FullSchemaFieldDto.typeCategory | null;
    urn?: string;
};

export namespace FullSchemaFieldDto {

    export enum entityType {
        INTEGRATION = 'INTEGRATION',
        DATASOURCE = 'DATASOURCE',
        DATASOURCE_INGESTION_RUN = 'DATASOURCE_INGESTION_RUN',
        DATASET = 'DATASET',
        DASHBOARD = 'DASHBOARD',
        CHART = 'CHART',
        COLLECTION = 'COLLECTION',
        DATASET_FIELD = 'DATASET_FIELD',
        DAG = 'DAG',
        TRANSFORMATION = 'TRANSFORMATION',
        RULE_RUN = 'RULE_RUN',
        INCIDENT = 'INCIDENT',
        USER = 'USER',
        ACCESS_TOKEN = 'ACCESS_TOKEN',
        SIFFLET_RULE = 'SIFFLET_RULE',
        CONFIG = 'CONFIG',
        TAG = 'TAG',
        DOMAIN = 'DOMAIN',
        ALERTING_HOOK = 'ALERTING_HOOK',
        RULE_MONITORING_RECOMMENDATION = 'RULE_MONITORING_RECOMMENDATION',
        DATAPOINT_QUALIFICATION = 'DATAPOINT_QUALIFICATION',
        DECLARED_ASSET = 'DECLARED_ASSET',
        WEBHOOK = 'WEBHOOK',
        SIFFLET_AGENT = 'SIFFLET_AGENT',
        SIFFLET_AGENT_JOB = 'SIFFLET_AGENT_JOB',
        AI_METADATA_PREDICTION = 'AI_METADATA_PREDICTION',
    }

    export enum typeCategory {
        DATE = 'DATE',
        TIME = 'TIME',
        DATETIME = 'DATETIME',
        BOOLEAN = 'BOOLEAN',
        STRING = 'STRING',
        INTEGER = 'INTEGER',
        FLOAT = 'FLOAT',
        ARRAY = 'ARRAY',
        JSON = 'JSON',
        STRUCT = 'STRUCT',
        MAP = 'MAP',
        BINARY = 'BINARY',
        OTHER = 'OTHER',
    }


}
