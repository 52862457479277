<script setup lang="ts">import { computed as _computed } from 'vue';


interface SCardProps {
  /**
   * Card title
   */
  title?: string,
  /**
   * Card subtitle
   */
  subtitle?: string,
  /**
   * Card icon, displayed as large icon on the left of title and subtitle
   */
  icon?: string,
  /**
   * Whether the card is disabled. If true, all card content and actions are disabled
   */
  disabled?: boolean,
  /**
   * Whether the card is outlined or not
   */
  outlined?: boolean
  /**
   * Card size
   */
  size?: 'default' | 'small'
}
const props = defineProps({
  title: { default: '' },
  subtitle: { default: '' },
  icon: { default: '' },
  disabled: { type: Boolean, default: false },
  outlined: { type: Boolean, default: true },
  size: { default: 'default' }
});

const hasTitleContent = _computed(() => !!props.title || !!props.subtitle || !!props.icon);

const titleClass = _computed(() => ({
  'body-1': props.size === 'default',
  'body-2': props.size === 'small',
}));

</script>

<template lang="pug">
v-card.scard.pa-4( v-bind="$attrs" :disabled="disabled" :outlined="outlined" )
  .scard-header.d-flex.justify-space-between.align-center( v-if="hasTitleContent" :class="{'pb-4': $slots.default}" )
    .d-flex
      SIcon.mr-2( v-if="icon" :icon="icon" size="large")
      div
        v-card-title.font-weight-medium.pa-0.ma-0.pr-2.textPrimary--text( v-if="title" :class="titleClass" ) {{ title }}
          slot( name="status")
        v-card-subtitle.caption.ma-0.pa-0.pr-2.textSecondary--text( v-if="subtitle" ) {{ subtitle }}
          slot( name="link" )
    v-spacer
    slot( name="action" )
  v-card-text.scard-content.pa-0.textPrimary--text(v-if="$slots.default")
    slot

</template>
