import { Route } from 'vue-router';
import {
 Action, Module, Mutation, VuexModule, config,
} from 'vuex-module-decorators';
import {
  RuleCatalogAssetDtoV2,
  MonitorService,
  BaseSearchFilterDto,
  Criticality,
  type RuleTemplateNameSearchFilterElementDto,
} from '@/api';
import router from '@/router';
import {
  queryToArray, queryToString, queryToNumber, QueryType, narrowArrayToUnion,
} from '@/utils/query';
import i18n from '@/i18n';
import AuthModule from './auth';

const DEFAULT_OPTIONS = {
  itemsPerPage: 10,
  page: 1,
};

const SORT_CONFIGURATION = [
  { value: 'lastRunDate', text: i18n.t('monitors.sort_configuration.last_run_date') },
  { value: 'ruleStatus', text: i18n.t('monitors.sort_configuration.monitor_status') },
  { value: 'criticality', text: i18n.t('monitors.sort_configuration.severity') },
  { value: 'name', text: i18n.t('monitors.sort_configuration.name') },
  { value: 'createdDate', text: i18n.t('monitors.sort_configuration.created_date') },
];

export const DEFAULT_SORT_CONFIGURATION = {
  sort: 'lastRunDate',
  direction: 'ASC',
};

type SearchParameters = Parameters<typeof MonitorService.getAllMonitor>[0]['requestBody'];

type CountByRunStatus = {
  PENDING: number;
  RUNNING: number;
  SUCCESS: number;
  FAILED: number;
  TECHNICAL_ERROR: number;
  REQUIRES_YOUR_ATTENTION: number;
};

type CountByRuleStatus = {
  NOT_EVALUATED: number;
  PASSING: number;
  NEEDS_ATTENTION: number;
  FAILING: number;
};

config.rawError = true;

let getAllMonitors: ReturnType<typeof MonitorService.getAllMonitor> | null = null;

@Module({ namespaced: true, name: 'monitors' })
export default class MonitorsModule extends VuexModule {
  count = 0;

  monitors: RuleCatalogAssetDtoV2[] = [];

  overallElements = -1;

  loading = false;

  sortConfiguration = SORT_CONFIGURATION;

  allFilters: BaseSearchFilterDto[] = [];

  leftPanelOpen = true;

  query: Route['query'] = {};

  countByRuleStatus: CountByRuleStatus = {
    NOT_EVALUATED: 0,
    PASSING: 0,
    NEEDS_ATTENTION: 0,
    FAILING: 0,
  };

  isEditing = false;

  savedSearch = '';

  selectedMonitors: RuleCatalogAssetDtoV2[] = [];

  get criticality() {
    const { criticality } = this.query;
    return narrowArrayToUnion<Criticality[]>(queryToArray(criticality), [Criticality.CRITICAL, Criticality.HIGH, Criticality.MODERATE, Criticality.LOW]);
  }

  get creationMethod() {
    const { creationMethod } = this.query;
    return queryToArray(creationMethod);
  }

  get datasource() {
    const { datasource } = this.query;
    return queryToArray(datasource);
  }

  get searchParametersWithDomain(): SearchParameters & { creationMethod?: string[] } {
    return {
      creationMethod: this.creationMethod,
      textSearch: this.textSearch,
      dataset: this.dataset,
      criticality: this.criticality,
      datasource: this.datasource,
      ruleTemplateName: this.monitorTemplateName,
      createdBy: this.createdBy,
      tag: this.tag,
      term: this.term,
      ruleStatus: this.ruleStatus,
      ...this.sorting,
      ...this.options,
      domain: this.selectedDomain?.name,
    };
  }

  get selectedDomain() {
    return this.context.rootState.auth.selectedDomain as AuthModule['selectedDomain'];
  }

  get getCount() {
    return this.count;
  }

  get getMonitors() {
    return this.monitors;
  }

  get getLoading() {
    return this.loading;
  }

  get textSearch() {
    return queryToString(this.query.textSearch);
  }

  get sorting() {
    const sort = queryToArray(this.query.sort);
    return {
      sort: sort.length ? sort : [`${DEFAULT_SORT_CONFIGURATION.sort},${DEFAULT_SORT_CONFIGURATION.direction}`],
    };
  }

  get options() {
    return {
      itemsPerPage: queryToNumber(this.query.itemsPerPage, DEFAULT_OPTIONS.itemsPerPage),
      page: queryToNumber(this.query.page, DEFAULT_OPTIONS.page),
    };
  }

  get filterQuantity() {
    const {
      textSearch,
      criticality,
      tag,
      term,
      dataset,
      datasource,
      ruleTemplateName,
      createdBy,
      creationMethod,
    } = this.searchParametersWithDomain;
    let filters = textSearch ? 1 : 0;
    filters += creationMethod?.length || 0;
    filters += criticality?.length || 0;
    filters += tag?.length || 0;
    filters += term?.length || 0;
    filters += dataset?.length || 0;
    filters += datasource?.length || 0;
    filters += ruleTemplateName?.length || 0;
    filters += createdBy?.length || 0;
    return filters;
  }

  get tag(): string[] | undefined {
    const { tag } = this.query;
    if (!tag) return undefined;
    return queryToArray(tag);
  }

  get term() {
    const { term } = this.query;
    if (!term) return undefined;
    return queryToArray(term);
  }

  get dataset() {
    const { dataset } = this.query;
    if (!dataset) return undefined;
    return queryToArray(dataset);
  }

  get ruleStatus() {
    const { ruleStatus } = this.query;
    if (!ruleStatus) return undefined;
    return narrowArrayToUnion<SearchParameters['ruleStatus']>(queryToArray(ruleStatus), ['NOT_EVALUATED', 'PASSING', 'NEEDS_ATTENTION', 'FAILING']);
  }

  get monitorTemplateName() {
    const { ruleTemplateName } = this.query;
    if (!ruleTemplateName) return undefined;
    return queryToArray(ruleTemplateName);
  }

  get createdBy() {
    const { createdBy } = this.query;
    if (!createdBy) return undefined;
    return queryToArray(createdBy);
  }

  get creationMethodFilter() {
    return this.allFilters.find((filter) => filter.type === BaseSearchFilterDto.type.CREATION_METHOD);
  }

  get platformFilter() {
    return this.allFilters.find((filter) => filter.type === BaseSearchFilterDto.type.PLATFORM);
  }

  get statusFilter() {
    return this.allFilters.find((filter) => filter.type === BaseSearchFilterDto.type.HEALTH_STATUS);
  }

  get templateFilter(): BaseSearchFilterDto | undefined {
    const result = this.allFilters.find((filter) => filter.type === BaseSearchFilterDto.type.RULE_TEMPLATE_NAME);
    if (!result) return undefined;
    // Use localized template names
    return {
      ...result,
      children: result.children?.map((child: RuleTemplateNameSearchFilterElementDto) => ({ ...child, name: i18n.t(`monitors.templates.${child.id}.name`) })),
    };
  }

  get ruleStatusFilter() {
    return this.allFilters.find((filter) => filter.type === BaseSearchFilterDto.type.RULE_STATUS);
  }

  get createdByFilter() {
    return this.allFilters.find((filter) => filter.type === BaseSearchFilterDto.type.CREATED_BY);
  }

  get criticalityFilter() {
    return this.allFilters.find((filter) => filter.type === BaseSearchFilterDto.type.CRITICALITY);
  }

  get tagFilter() {
    return this.allFilters.find((filter) => filter.type === BaseSearchFilterDto.type.TAG);
  }

  get termFilter() {
    return this.allFilters.find((filter) => filter.type === BaseSearchFilterDto.type.TERM);
  }

  @Mutation
  setOverallElements(overallElements: number) {
    this.overallElements = overallElements;
  }

  @Mutation
  routeChanged(query: Route['query']) {
    this.query = Object.freeze(query);
  }

  @Mutation
  toggleLeftPanel() {
    this.leftPanelOpen = !this.leftPanelOpen;
  }

  @Mutation
  setFilters(filters: BaseSearchFilterDto[]) {
    this.allFilters = filters;
  }

  @Mutation
  clearFilters() {
    router.push({
      query: {
        ...this.query,
        creationMethod: [],
        criticality: [],
        tag: [],
        term: [],
        dataset: [],
        datasource: [],
        ruleTemplateName: [],
        createdBy: [],
      },
    });
  }

  @Mutation
  resetState() {
    this.count = 0;
    this.monitors = [];
  }

  @Mutation
  setCount(count: number) {
    this.count = count;
  }

  @Mutation
  setMonitors(monitors: RuleCatalogAssetDtoV2[]) {
    this.monitors = monitors;
  }

  @Mutation
  setSelectedMonitors(monitors: RuleCatalogAssetDtoV2[]) {
    this.selectedMonitors = monitors;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  toggleEdit() {
    this.isEditing = !this.isEditing;
  }

  @Mutation
  closeEdit() {
    this.isEditing = false;
  }

  @Mutation
  resetCountByRunStatus() {
    this.countByRuleStatus = {
      NOT_EVALUATED: 0,
      PASSING: 0,
      NEEDS_ATTENTION: 0,
      FAILING: 0,
    };
  }

  @Action
  async searchMonitors() {
    const FAKE_FILTER = {
      type: 'CREATION_METHOD',
      query: 'creationMethod',
      children: [
        {
          results: 45,
          id: 'INTERFACE_CREATED',
        },
        {
          results: 64,
          id: 'DBT_INGESTION',
        },
        {
          results: 188,
          id: 'AS_CODE',
          children: [
            {
              results: 188,
              id: 'AS_CODE:00000000-0000-0000-0000-000000000000',
              name: 'Customer insights',
            },
            {
              results: 188,
              id: 'AS_CODE:00000000-0000-0000-0000-000000000001',
              name: 'Sales performance',
            },
          ],
        },
        {
          results: 4225,
          id: 'API',
        },
        {
          results: 4225,
          id: 'AUTO_COVERAGE',
        },
        {
          results: 4225,
          id: 'TABLE_METADATA_MONITORING',
        },
      ],
    };
    if (this.loading && getAllMonitors) getAllMonitors.cancel();
    getAllMonitors = MonitorService.getAllMonitor({ requestBody: this.searchParametersWithDomain });
    this.setLoading(true);
    this.setCount(0);
    this.setMonitors([]);
    const { catalogFilters, searchRules } = await getAllMonitors;
    const { filteredElements, overallElements, data } = searchRules;
    this.setFilters([...catalogFilters, FAKE_FILTER] as any);
    this.setCount(filteredElements!);
    this.setOverallElements(overallElements!);
    this.setMonitors(data!);
    this.setLoading(false);
  }

  @Action
  async getSummary() {
    this.resetCountByRunStatus();
    const { countByRuleStatus } = await MonitorService.getMonitorsStatusSummary({
      domain: this.selectedDomain?.name,
    }) as { countByRuleStatus: CountByRuleStatus, countByRunStatus: CountByRunStatus };
    for (const [key, value] of Object.entries(countByRuleStatus)) {
      this.countByRuleStatus[key as keyof CountByRuleStatus] = value;
    }
  }

  @Action
  /**
   * Sets the values for the catalog query parameters and updates the URL.
   * @param values - An object containing the query parameters and their values.
   * @param resetPage - Optional. Specifies whether to reset the 'page' parameter to '1'. Default is true.
   */
  setValues(values: { [key: string]: QueryType}) {
    router.push({
      query: {
        ...this.query,
        ...values,
        ...(!('page' in values) && { page: DEFAULT_OPTIONS.page.toString() }),
      },
    });
  }
}
