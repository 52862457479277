/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { AccessTokenProviderDto } from './AccessTokenProviderDto';
import type { AlertingHookReferenceDto } from './AlertingHookReferenceDto';
import type { DatasetBriefDto } from './DatasetBriefDto';
import type { DatasourceProviderDto } from './DatasourceProviderDto';
import type { GenericProviderDto } from './GenericProviderDto';
import type { JsonNode } from './JsonNode';
import type { LastRunStatusDto } from './LastRunStatusDto';
import type { RuleStatusDto } from './RuleStatusDto';
import type { TagReferenceDto } from './TagReferenceDto';
import type { UserDto } from './UserDto';
import type { UserProviderDto } from './UserProviderDto';

export type RuleCatalogAssetDtoV2 = {
    canManuallyRun?: boolean;
    createIncidentOnFail?: boolean;
    createdBy?: UserDto;
    criticality: number;
    datasets?: Array<DatasetBriefDto>;
    description?: string;
    hasAiRecommendations: boolean;
    id: string;
    lastRunId?: string | null;
    lastRunStatus?: LastRunStatusDto;
    lastWeekStatuses?: Array<LastRunStatusDto>;
    mails?: Array<AlertingHookReferenceDto>;
    message?: string;
    msTeams?: Array<AlertingHookReferenceDto>;
    multiDimensional: boolean;
    name: string;
    provider?: (AccessTokenProviderDto | DatasourceProviderDto | GenericProviderDto | UserProviderDto);
    readOnly?: boolean;
    ruleLabel?: string;
    ruleParams?: JsonNode;
    ruleStatus: RuleStatusDto;
    ruleTemplateName: string;
    ruleType: RuleCatalogAssetDtoV2.ruleType;
    schedule?: string;
    selectable: boolean;
    slackChannels?: Array<AlertingHookReferenceDto>;
    sourcePlatform: RuleCatalogAssetDtoV2.sourcePlatform;
    supportAsCodeYAMLConversion: boolean;
    tags?: Array<TagReferenceDto>;
    terms?: Array<TagReferenceDto>;
    webhooks?: Array<AlertingHookReferenceDto>;
};

export namespace RuleCatalogAssetDtoV2 {

    export enum ruleType {
        AUTOMATIC_RULE = 'AUTOMATIC_RULE',
        CUSTOM_RULE = 'CUSTOM_RULE',
        SIFFLET_RULE = 'SIFFLET_RULE',
    }

    export enum sourcePlatform {
        SIFFLET = 'SIFFLET',
        DBT = 'DBT',
    }


}
