import {
  MAIN_ROUTES,
  SUB_ROUTES,
} from '@/router/components';
import { BackgroundColors } from '@/@types/types';

export default {
  path: 'sources',
  meta: {
    requiresAuth: true,
    breadCrumb: 'app.navigation-drawer.sources',
  },
  component: MAIN_ROUTES.DATASOURCES,
  children: [{
    path: '',
    name: 'sources',
    meta: {
      requiresAuth: true,
      hideBreadCrumb: true,
      breadCrumb: 'sources.search',
      background: BackgroundColors.SECONDARY,
      appBar: BackgroundColors.PRIMARY,
    },
    component: SUB_ROUTES.DATASOURCES_SEARCH,
  }, {
    path: 'integrations',
    name: 'sourcesV2',
    meta: {
      requiresAuth: true,
      breadCrumb: 'sources.search',
      breadCrumbFromParamName: true,
      background: BackgroundColors.SECONDARY,
      appBar: BackgroundColors.PRIMARY,
    },
    props: true,
    component: SUB_ROUTES.INTEGRATION_LIST,
  }, {
    path: 'integrations/:id',
    name: 'sourcesV2.source',
    meta: {
      requiresAuth: true,
      breadCrumb: 'sources.search',
      breadCrumbFromParamName: true,
      background: BackgroundColors.SECONDARY,
      appBar: BackgroundColors.PRIMARY,
    },
    props: true,
    component: SUB_ROUTES.INTEGRATION_LIST,
  }, {
    path: 'source/add',
    name: 'sources.source.new',
    meta: {
      requiresAuth: true,
      breadCrumb: 'sources.new_source',
      breadCrumbFromParamName: true,
      background: BackgroundColors.SECONDARY,
      appBar: BackgroundColors.PRIMARY,
    },
    props: true,
    component: SUB_ROUTES.DATASOURCE_ADD,
  }, {
    path: 'integration/add',
    name: 'sources.integration.new',
    meta: {
      requiresAuth: true,
      breadCrumb: 'sources.new_source',
      breadCrumbFromParamName: true,
      background: BackgroundColors.SECONDARY,
      appBar: BackgroundColors.PRIMARY,
    },
    props: true,
    component: SUB_ROUTES.INTEGRATION_ADD,
  }, {
    path: 'source/:id/',
    name: 'sources.source',
    meta: {
      requiresAuth: true,
      breadCrumbFromParamName: true,
      params: ['id', 'name'],
      background: BackgroundColors.SECONDARY,
      appBar: BackgroundColors.PRIMARY,
    },
    props: true,
    component: SUB_ROUTES.DATASOURCE,
    children: [{
      path: 'overview',
      name: 'sources.source.overview',
      props: true,
      meta: {
        requiresAuth: true,
        hideBreadCrumb: true,
        breadCrumb: 'sources.overview',
        background: BackgroundColors.SECONDARY,
        appBar: BackgroundColors.PRIMARY,
      },
      component: SUB_ROUTES.DATASOURCE_OVERVIEW,
    }, {
      path: 'runs',
      name: 'sources.source.runs',
      props: true,
      meta: {
        requiresAuth: true,
        hideBreadCrumb: true,
        breadCrumb: 'sources.runs',
        background: BackgroundColors.SECONDARY,
        appBar: BackgroundColors.PRIMARY,
      },
      component: SUB_ROUTES.DATASOURCE_RUNS,
    }],
  }, {
    path: 'source/:id/edit',
    name: 'sources.source.edit',
    meta: {
      requiresAuth: true,
      breadCrumb: 'sources.edit_source',
      breadCrumbFromParamName: true,
      background: BackgroundColors.SECONDARY,
      appBar: BackgroundColors.PRIMARY,
    },
    props: true,
    component: SUB_ROUTES.DATASOURCE_EDIT,
  }, {
    path: 'integration/:id/edit',
    name: 'sources.integration.edit',
    meta: {
      requiresAuth: true,
      breadCrumb: 'sources.edit_source',
      breadCrumbFromParamName: true,
      background: BackgroundColors.SECONDARY,
      appBar: BackgroundColors.PRIMARY,
    },
    props: true,
    component: SUB_ROUTES.INTEGRATION_EDIT,
  }, {
    path: 'source/:id/copy',
    name: 'sources.source.copy',
    meta: {
      requiresAuth: true,
      breadCrumb: 'sources.copy',
      breadCrumbFromParamName: true,
      background: BackgroundColors.SECONDARY,
      appBar: BackgroundColors.PRIMARY,
    },
    props: true,
    component: SUB_ROUTES.DATASOURCE_EDIT,
  }],
};
