<script setup lang="ts">import { computed as _computed } from 'vue';

import Vue from 'vue';
import { getModule } from 'vuex-module-decorators';
import {
  RuleService,
  RuleCatalogAssetDtoV2,
} from '@/api';
import { DataItemProps } from 'vuetify';
import i18n from '@/i18n';
import type { MonitorSchema } from '@/modules/monitors/monitor-wizard/monitor-wizard-schema';
import store from '@/store';
import authModule from '@/store/modules/auth';
import { useRouter } from 'vue-router/composables';
import getSettingsFromMonitorType from '@/modules/monitors/monitor-wizard/monitor-configurations/monitor-configurations';

const router = useRouter();
const auth = getModule(authModule, store);

interface Props {
  itemProps: Omit<DataItemProps, 'item'> & { item: RuleCatalogAssetDtoV2 }
}

type CardActionEmits = {
  (event: 'run'): void
  (event: 'edit', item: RuleCatalogAssetDtoV2): void
  (event: 'show-yaml', item: RuleCatalogAssetDtoV2): void
  (event: 'copy-id', item: RuleCatalogAssetDtoV2): void
  (event: 'delete', item: RuleCatalogAssetDtoV2): void
  (event: 'createIncident', item: RuleCatalogAssetDtoV2): void
  (event: 'linkIncident', item: RuleCatalogAssetDtoV2): void
}
const emit = defineEmits(["run", "edit", "show-yaml", "copy-id", "delete", "createIncident", "linkIncident"]);

const props = defineProps({
  itemProps: null
});

const item = _computed(() => props.itemProps.item);
const canManuallyRun = _computed(() => item.value.canManuallyRun);
const canRun = _computed(() => auth.userActions['monitoring.monitor.write'] && canManuallyRun.value);
const canEdit = _computed(() => auth.userActions['monitoring.monitor.write'] && !item.value.readOnly);
const canGenerateYaml = _computed(() => item.value.supportAsCodeYAMLConversion);
const canDelete = _computed(() => auth.userActions['monitoring.monitor.write'] && !item.value.readOnly);
const monitorCloneLink = _computed(() => ({
  name: 'monitors.rule.clone',
  params: {
    id: item.value.id,
  },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const canClone = _computed(() => {
  const _canClone = getSettingsFromMonitorType(item.value.ruleTemplateName as MonitorSchema['ruleTemplateName'])?.canClone;
  return _canClone !== false;
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const cloneMonitor = () => {
  router.push(monitorCloneLink.value);
};

const runMonitor = async () => {
  Vue.notify({
    text: i18n.t('monitors.run_rule_success', { name: item.value.name }) as string,
  });
  await RuleService.siffletRuleManualRun({ id: item.value.id });
  emit('run');
};
</script>

<template lang="pug">
.d-flex.justify-end.align-center

  SButton.mr-2(
    :disabled="!canRun"
    icon="icon-play-circle"
    color="secondary"
    data-cy="monitors-result-card-menu-run"
    variant="text"
    @click.native.prevent.stop="runMonitor")

  SThreeDotMenu(
    data-cy="monitors-result-card-menu-button"
    variant="text")
    SMenuButton(
      :disabled="!canEdit"
      @click="emit('edit', item)"
      data-cy="monitors-result-card-menu-edit"
      icon="icon-edit"
      :text="$t('monitors.edit.edit')")
    SMenuButton(
      @click="emit('linkIncident', item)"
      data-cy="monitors-result-card-menu-link-incident"
      icon="icon-flag-add"
      :text="$t('monitors.edit.link_existing_incident')")
    SMenuButton(
      @click="emit('createIncident', item)"
      data-cy="monitors-result-card-menu-create-incident"
      icon="icon-add"
      :text="$t('rules.create_incident')")
    SMenuButton(
      :disabled="!canGenerateYaml"
      @click="emit('show-yaml', item)"
      data-cy="monitors-result-card-menu-show-yaml"
      icon="icon-code-brackets"
      :text="$t('monitors.show_as_yaml_code')")
    SMenuButton(
      @click="emit('copy-id', item)"
      data-cy="monitors-result-card-menu-copy-id"
      icon="icon-copy"
      :text="$t('rules.copy_id')")
    SMenuButton(
      :disabled="!canDelete"
      @click="emit('delete', item)"
      data-cy="monitors-result-card-menu-delete"
      icon="icon-trash"
      color="danger"
      :text="$t('monitors.delete')")

</template>
